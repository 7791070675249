<template>
  <button
    class="t-button flex min-w-fit items-center justify-center rounded-4xl border p-4 backdrop-blur-sm transition-all duration-200"
    :class="{
      'bg-primary-700/10 dark:bg-primary-400/20  text-primary-900 dark:text-primary-200 dark:border-primary-900 border-primary-300 border-block-4':
        active,
      'border-block-3 bg-block-2 text-text-2 hover:border-block-4 hover:bg-block-3 hover:text-text-1':
        !active,
    }"
    @click="emit('click', $event)"
  >
    <slot></slot>
  </button>
</template>
<script setup lang="ts">
defineProps<{
  active: boolean
}>()

interface FeatureButtonEvents {
  (e: 'click', event: MouseEvent): void
}
const emit = defineEmits<FeatureButtonEvents>()
</script>
