const CSS_VARIABLES = [
  '--color-primary-50',
  '--color-primary-100',
  '--color-primary-200',
  '--color-primary-300',
  '--color-primary-400',
  '--color-primary-500',
  '--color-primary-600',
  '--color-primary-700',
  '--color-primary-800',
  '--color-primary-900',
  '--color-primary-950',
  '--color-primary-DEFAULT',
]

export function getCSSVariables() {
  if (typeof window === 'undefined') return {}
  const rootStyles = getComputedStyle(document.documentElement)
  const variables = {}

  // Retrieve values for each variable name
  CSS_VARIABLES.forEach((name) => {
    variables[name] = rootStyles.getPropertyValue(name).trim()
  })

  return variables
}

export function setCSSVariables(variables) {
  const root = document.documentElement
  Object.entries(variables).forEach(([name, value]) => {
    root.style.setProperty(name, value)
  })
}
