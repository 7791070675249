<template>
  <svg
    viewBox="0 0 210 195"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- width="210" height="195" -->
    <g filter="url(#filter0_bd_220_29151)">
      <circle
        cx="105"
        cy="104.548"
        r="80"
        fill="url(#paint0_linear_220_29151)"
        fill-opacity="0.03"
        shape-rendering="crispEdges"
      />
      <circle
        cx="105"
        cy="104.548"
        r="79.5"
        stroke="url(#paint1_linear_220_29151)"
        stroke-opacity="0.05"
        shape-rendering="crispEdges"
      />
    </g>
    <path
      d="M56.0121 101.42C55.3887 101.965 55.3257 102.913 55.8713 103.536C56.4169 104.16 57.3645 104.223 57.9879 103.677L56.0121 101.42ZM145.996 60.9433C145.938 60.1169 145.221 59.494 144.395 59.552L130.928 60.4971C130.102 60.5551 129.479 61.272 129.537 62.0984C129.595 62.9248 130.312 63.5477 131.138 63.4897L143.109 62.6497L143.949 74.6202C144.007 75.4466 144.724 76.0695 145.55 76.0115C146.376 75.9535 146.999 75.2366 146.941 74.4102L145.996 60.9433ZM124.391 84.1922L123.259 83.2084L124.391 84.1922ZM57.9879 103.677L74.8398 88.9274L72.8639 86.67L56.0121 101.42L57.9879 103.677ZM87.8172 85.7493L108.177 90.7023L108.886 87.7873L88.5263 82.8343L87.8172 85.7493ZM125.524 85.176L145.632 62.0321L143.368 60.0645L123.259 83.2084L125.524 85.176ZM108.177 90.7023C114.538 92.2498 121.23 90.1177 125.524 85.176L123.259 83.2084C119.702 87.3029 114.156 89.0695 108.886 87.7873L108.177 90.7023ZM74.8398 88.9274C78.3907 85.8194 83.2319 84.6338 87.8172 85.7493L88.5263 82.8343C82.9924 81.4881 77.1495 82.919 72.8639 86.67L74.8398 88.9274Z"
      fill="rgb(var(--color-primary-600))"
    />
    <g filter="url(#filter1_b_220_29151)">
      <rect
        x="57"
        y="124.548"
        width="8"
        height="28"
        rx="4"
        fill="url(#paint2_linear_220_29151)"
        fill-opacity="0.3"
      />
      <rect
        x="57.4"
        y="124.948"
        width="7.2"
        height="27.2"
        rx="3.6"
        stroke="url(#paint3_linear_220_29151)"
        stroke-opacity="0.08"
        stroke-width="0.8"
      />
    </g>
    <g filter="url(#filter2_b_220_29151)">
      <rect
        x="74.6001"
        y="110.548"
        width="8"
        height="42"
        rx="4"
        fill="url(#paint4_linear_220_29151)"
        fill-opacity="0.3"
      />
      <rect
        x="75.0001"
        y="110.948"
        width="7.2"
        height="41.2"
        rx="3.6"
        stroke="url(#paint5_linear_220_29151)"
        stroke-opacity="0.08"
        stroke-width="0.8"
      />
    </g>
    <g filter="url(#filter3_b_220_29151)">
      <rect
        x="92.2"
        y="116.548"
        width="8"
        height="36"
        rx="4"
        fill="url(#paint6_linear_220_29151)"
        fill-opacity="0.3"
      />
      <rect
        x="92.6"
        y="116.948"
        width="7.2"
        height="35.2"
        rx="3.6"
        stroke="url(#paint7_linear_220_29151)"
        stroke-opacity="0.08"
        stroke-width="0.8"
      />
    </g>
    <g filter="url(#filter4_b_220_29151)">
      <rect
        x="109.8"
        y="113.548"
        width="8"
        height="39"
        rx="4"
        fill="url(#paint8_linear_220_29151)"
        fill-opacity="0.3"
      />
      <rect
        x="110.2"
        y="113.948"
        width="7.2"
        height="38.2"
        rx="3.6"
        stroke="url(#paint9_linear_220_29151)"
        stroke-opacity="0.08"
        stroke-width="0.8"
      />
    </g>
    <g filter="url(#filter5_b_220_29151)">
      <rect
        x="127.4"
        y="104.548"
        width="8"
        height="48"
        rx="4"
        fill="url(#paint10_linear_220_29151)"
        fill-opacity="0.3"
      />
      <rect
        x="127.8"
        y="104.948"
        width="7.2"
        height="47.2"
        rx="3.6"
        stroke="url(#paint11_linear_220_29151)"
        stroke-opacity="0.08"
        stroke-width="0.8"
      />
    </g>
    <g filter="url(#filter6_b_220_29151)">
      <rect
        x="145"
        y="88.5483"
        width="8"
        height="64"
        rx="4"
        fill="url(#paint12_linear_220_29151)"
        fill-opacity="0.3"
      />
      <rect
        x="145.4"
        y="88.9483"
        width="7.2"
        height="63.2"
        rx="3.6"
        stroke="url(#paint13_linear_220_29151)"
        stroke-opacity="0.08"
        stroke-width="0.8"
      />
    </g>
    <defs>
      <filter
        id="filter0_bd_220_29151"
        x="1"
        y="0.548828"
        width="208"
        height="208"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feGaussianBlur
          in="BackgroundImageFix"
          stdDeviation="12"
        />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_220_29151"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-8" />
        <feGaussianBlur stdDeviation="8" />
        <feComposite
          in2="hardAlpha"
          operator="out"
        />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_backgroundBlur_220_29151"
          result="effect2_dropShadow_220_29151"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_220_29151"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_b_220_29151"
        x="33"
        y="100.549"
        width="56"
        height="76"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feGaussianBlur
          in="BackgroundImageFix"
          stdDeviation="12"
        />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_220_29151"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_220_29151"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_b_220_29151"
        x="50.6001"
        y="86.5488"
        width="56"
        height="90"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feGaussianBlur
          in="BackgroundImageFix"
          stdDeviation="12"
        />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_220_29151"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_220_29151"
          result="shape"
        />
      </filter>
      <filter
        id="filter3_b_220_29151"
        x="68.1997"
        y="92.5488"
        width="56"
        height="84"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feGaussianBlur
          in="BackgroundImageFix"
          stdDeviation="12"
        />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_220_29151"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_220_29151"
          result="shape"
        />
      </filter>
      <filter
        id="filter4_b_220_29151"
        x="85.8003"
        y="89.5488"
        width="56"
        height="87"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feGaussianBlur
          in="BackgroundImageFix"
          stdDeviation="12"
        />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_220_29151"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_220_29151"
          result="shape"
        />
      </filter>
      <filter
        id="filter5_b_220_29151"
        x="103.4"
        y="80.5488"
        width="56"
        height="96"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feGaussianBlur
          in="BackgroundImageFix"
          stdDeviation="12"
        />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_220_29151"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_220_29151"
          result="shape"
        />
      </filter>
      <filter
        id="filter6_b_220_29151"
        x="121"
        y="64.5488"
        width="56"
        height="112"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feGaussianBlur
          in="BackgroundImageFix"
          stdDeviation="12"
        />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_220_29151"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_220_29151"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_220_29151"
        x1="105"
        y1="24.5483"
        x2="105"
        y2="184.548"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--j-black)" />
        <stop
          offset="0.83"
          stop-color="var(--j-black)"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint1_linear_220_29151"
        x1="105"
        y1="24.5483"
        x2="105"
        y2="167.548"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--j-black)" />
        <stop
          offset="1"
          stop-color="var(--j-black)"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint2_linear_220_29151"
        x1="61"
        y1="124.548"
        x2="61"
        y2="152.548"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="rgb(var(--color-primary-400))" />
        <stop
          offset="0.84"
          stop-color="rgb(var(--color-primary-400))"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint3_linear_220_29151"
        x1="61"
        y1="124.548"
        x2="74.9971"
        y2="138.831"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop
          offset="0.13"
          stop-opacity="0.1"
        />
        <stop
          offset="0.305"
          stop-opacity="0.8"
        />
        <stop
          offset="0.565"
          stop-opacity="0.1"
        />
        <stop
          offset="0.786809"
          stop-opacity="0"
        />
        <stop
          offset="1"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint4_linear_220_29151"
        x1="78.6001"
        y1="110.548"
        x2="78.6001"
        y2="152.548"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="rgb(var(--color-primary-400))" />
        <stop
          offset="0.84"
          stop-color="rgb(var(--color-primary-400))"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint5_linear_220_29151"
        x1="78.6001"
        y1="110.548"
        x2="98.1325"
        y2="123.836"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop
          offset="0.13"
          stop-opacity="0.1"
        />
        <stop
          offset="0.305"
          stop-opacity="0.8"
        />
        <stop
          offset="0.565"
          stop-opacity="0.1"
        />
        <stop
          offset="0.786809"
          stop-opacity="0"
        />
        <stop
          offset="1"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint6_linear_220_29151"
        x1="96.2"
        y1="116.548"
        x2="96.1999"
        y2="152.548"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="rgb(var(--color-primary-400))" />
        <stop
          offset="0.84"
          stop-color="rgb(var(--color-primary-400))"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint7_linear_220_29151"
        x1="96.2"
        y1="116.548"
        x2="113.73"
        y2="130.461"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop
          offset="0.13"
          stop-opacity="0.1"
        />
        <stop
          offset="0.305"
          stop-opacity="0.8"
        />
        <stop
          offset="0.565"
          stop-opacity="0.1"
        />
        <stop
          offset="0.786809"
          stop-opacity="0"
        />
        <stop
          offset="1"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint8_linear_220_29151"
        x1="113.8"
        y1="113.548"
        x2="113.8"
        y2="152.548"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="rgb(var(--color-primary-400))" />
        <stop
          offset="0.84"
          stop-color="rgb(var(--color-primary-400))"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint9_linear_220_29151"
        x1="113.8"
        y1="113.548"
        x2="132.393"
        y2="127.169"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop
          offset="0.13"
          stop-opacity="0.1"
        />
        <stop
          offset="0.305"
          stop-opacity="0.8"
        />
        <stop
          offset="0.565"
          stop-opacity="0.1"
        />
        <stop
          offset="0.786809"
          stop-opacity="0"
        />
        <stop
          offset="1"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint10_linear_220_29151"
        x1="131.4"
        y1="104.548"
        x2="131.4"
        y2="152.548"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="rgb(var(--color-primary-400))" />
        <stop
          offset="0.84"
          stop-color="rgb(var(--color-primary-400))"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint11_linear_220_29151"
        x1="131.4"
        y1="104.548"
        x2="152.497"
        y2="117.106"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop
          offset="0.13"
          stop-opacity="0.1"
        />
        <stop
          offset="0.305"
          stop-opacity="0.8"
        />
        <stop
          offset="0.565"
          stop-opacity="0.1"
        />
        <stop
          offset="0.786809"
          stop-opacity="0"
        />
        <stop
          offset="1"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint12_linear_220_29151"
        x1="149"
        y1="88.5483"
        x2="149"
        y2="152.548"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="rgb(var(--color-primary-400))" />
        <stop
          offset="0.84"
          stop-color="rgb(var(--color-primary-400))"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint13_linear_220_29151"
        x1="149"
        y1="88.5483"
        x2="172.823"
        y2="99.1838"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop
          offset="0.13"
          stop-opacity="0.1"
        />
        <stop
          offset="0.305"
          stop-opacity="0.8"
        />
        <stop
          offset="0.565"
          stop-opacity="0.1"
        />
        <stop
          offset="0.786809"
          stop-opacity="0"
        />
        <stop
          offset="1"
          stop-opacity="0"
        />
      </linearGradient>
    </defs>
  </svg>
</template>
