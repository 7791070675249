<template>
  <div class="flex w-full flex-col gap-8 lg:gap-10">
    <div
      class="flex grid-cols-1 flex-col gap-8 sm:grid sm:grid-cols-2 lg:grid-cols-3 lg:gap-10"
    >
      <div
        v-motion="{
          initial: {
            y: 100,
            opacity: 0.2,
          },
          visibleOnce: {
            y: 0,
            opacity: 1,
            transition: {
              type: 'spring',
              stiffness: 250,
              damping: 25,
              mass: 0.5,
            },
          },
        }"
        class="col-span-2 flex flex-col gap-3 lg:col-span-1 lg:gap-6"
      >
        <h2
          class="t-heading-3 lg:t-heading-2 text-text-1"
          v-html="t('homePage.HtmlWhatMakesUsCoolTitle')"
        />
        <span class="t-subtitle text-text-2">
          {{ t('homePage.whatMakesUsDescription') }}
        </span>
      </div>
      <GradientCard
        v-motion="{
          initial: {
            y: 100,
            opacity: 0.2,
          },
          visibleOnce: {
            y: 0,
            opacity: 1,
            transition: {
              type: 'spring',
              stiffness: 250,
              damping: 25,
              mass: 0.5,
            },
          },
        }"
        class="col-span-1 mx-auto max-w-md md:mx-0 md:max-w-none"
      >
        <ClientOnly>
          <div class="flex flex-col items-center">
            <SvgCustomizable class="max-w-52 md:max-w-none" />
            <div class="mb-2 flex flex-wrap">
              <UTooltip
                v-for="color in displayedPrimaryColors"
                :key="color.name"
                class="capitalize"
                :text="color.name"
                :open-delay="300"
              >
                <UButton
                  variant="ghost"
                  size="xs"
                  :aria-label="`Switch to ${color.name}`"
                  @click="onPrimaryColorChange(color.name)"
                >
                  <span
                    class="inline-block h-3 w-3 rounded-full"
                    :style="{ backgroundColor: color.color }"
                  />
                </UButton>
              </UTooltip>
            </div>
            <div
              class="dark:bg-primary-800 bg-primary-200 dark:text-primary-200 text-primary-800 t-button mb-8 rounded px-2 py-0.5 capitalize"
            >
              {{ appConfig.ui.primary }}
            </div>
            <span class="t-heading-4 mb-4 text-center text-text-1">
              {{ t('homePage.customizableTitle') }}
            </span>
            <span class="t-body text-center text-text-2">
              {{ t('homePage.customizableDesc') }}
            </span>
          </div>
        </ClientOnly>
      </GradientCard>
      <GradientCard
        v-motion="{
          initial: {
            y: 100,
            opacity: 0.2,
          },
          visibleOnce: {
            y: 0,
            opacity: 1,
            transition: {
              type: 'spring',
              stiffness: 250,
              damping: 25,
              mass: 0.5,
            },
          },
        }"
        class="col-span-1 mx-auto max-w-md md:mx-0 md:max-w-none"
      >
        <div class="flex flex-col items-center">
          <SvgMultilingual class="mb-á max-w-52 md:max-w-none" />
          <span class="t-heading-4 mb-4 text-center text-text-1">
            {{ t('homePage.multilingualTitle') }}
          </span>
          <span class="t-body text-center text-text-2">{{ t('homePage.multilingualDesc') }}
          </span>
        </div>
      </GradientCard>
    </div>

    <div class="flex grid-cols-2 flex-col gap-8 sm:grid lg:gap-10">
      <GradientCard
        v-motion="{
          initial: {
            y: 100,
            opacity: 0.2,
          },
          visibleOnce: {
            y: 0,
            opacity: 1,
            transition: {
              type: 'spring',
              stiffness: 250,
              damping: 25,
              mass: 0.5,
            },
          },
        }"
        class="mx-auto max-w-md md:mx-0 md:max-w-none"
      >
        <div class="flex flex-col items-center">
          <IntersectingThemeVideo
            light="/video/responsive-light.webm"
            dark="/video/responsive-dark.webm"
            poster-dark="/img/responsive-poster-dark.png"
            poster-light="/img/responsive-poster-light.png"
            loop
          />

          <span class="t-heading-4 mb-4 text-center text-text-1">
            {{ t('homePage.responsiveTitle') }}
          </span>
          <span class="t-body text-center text-text-2">
            {{ t('homePage.responsiveDesc') }}
          </span>
        </div>
      </GradientCard>
      <GradientCard
        v-motion="{
          initial: {
            y: 100,
            opacity: 0.2,
          },
          visibleOnce: {
            y: 0,
            opacity: 1,
            transition: {
              type: 'spring',
              stiffness: 250,
              damping: 25,
              mass: 0.5,
            },
          },
        }"
        class="mx-auto max-w-md md:mx-0 md:max-w-none"
      >
        <div class="flex flex-1 flex-col items-center">
          <IntersectingThemeVideo
            light="/video/regular-updates-light.webm"
            dark="/video/regular-updates-dark.webm"
            poster-dark="/img/regular-updates-poster-dark.png"
            poster-light="/img/regular-updates-poster-light.png"
            loop
          />

          <span class="t-heading-4 mb-4 mt-auto text-center text-text-1">
            {{ t('homePage.regularUpdatesTitle') }}
          </span>
          <span class="t-body text-center text-text-2">
            {{ t('homePage.regularUpdatesDesc') }}
          </span>
        </div>
      </GradientCard>
    </div>

    <div class="grid gap-8 sm:grid-cols-2 lg:grid-cols-3 lg:gap-10">
      <GradientCard
        v-motion="{
          initial: {
            y: 100,
            opacity: 0.2,
          },
          visibleOnce: {
            y: 0,
            opacity: 1,
            transition: {
              type: 'spring',
              stiffness: 250,
              damping: 25,
              mass: 0.5,
            },
          },
        }"
        class="mx-auto max-w-md overflow-hidden md:mx-0 md:max-w-none"
      >
        <div class="flex w-full flex-col items-center">
          <SvgRobustPermissions class="mt-9 max-w-52 md:max-w-none" />

          <div class="flex flex-col px-6">
            <span class="t-heading-4 mb-4 text-center text-text-1">
              {{ t('homePage.robustPermissionsTitle') }}
            </span>
            <span class="t-body text-center text-text-2">
              {{ t('homePage.robustPermissionsDesc') }}
            </span>
          </div>
        </div>
      </GradientCard>
      <GradientCard
        v-motion="{
          initial: {
            y: 100,
            opacity: 0.2,
          },
          visibleOnce: {
            y: 0,
            opacity: 1,
            transition: {
              type: 'spring',
              stiffness: 250,
              damping: 25,
              mass: 0.5,
            },
          },
        }"
        class="mx-auto max-w-md md:mx-0 md:max-w-none"
      >
        <div class="flex flex-col items-center">
          <SvgReliableSupport class="max-w-52 md:max-w-none" />

          <span class="t-heading-4 mb-4 text-center text-text-1">
            {{ t('homePage.reliableSupportTitle') }}
          </span>
          <span class="t-body text-center text-text-2">
            {{ t('homePage.reliableSupportDesc') }}
          </span>
        </div>
      </GradientCard>
      <GradientCard
        v-motion="{
          initial: {
            y: 100,
            opacity: 0.2,
          },
          visibleOnce: {
            y: 0,
            opacity: 1,
            transition: {
              type: 'spring',
              stiffness: 250,
              damping: 25,
              mass: 0.5,
            },
          },
        }"
        class="mx-auto max-w-md md:mx-0 md:max-w-none"
      >
        <div class="flex flex-col items-center">
          <SvgModularity class="max-w-52 md:max-w-none" />
          <span class="t-heading-4 mb-4 text-center text-text-1">
            {{ t('homePage.modularityTitle') }}
          </span>
          <span class="t-body text-center text-text-2">
            {{ t('homePage.modularityDesc') }}
          </span>
        </div>
      </GradientCard>
    </div>
  </div>
</template>
<script setup lang="ts">
import { getCSSVariables } from '@/utils/styles'
import { PrimaryColors } from '@/utils/constants/index'
const { $isSafari: isSafari } = useNuxtApp()
const appConfig = useAppConfig()
const { t } = useI18n()

const safariBrowser = ref(isSafari)
function onPrimaryColorChange(color: string) {
  appConfig.ui.primary = color
  const variables = getCSSVariables()
  console.log(variables)
  localStorage.setItem('nuxt-ui-root', JSON.stringify(variables))
  localStorage.setItem('nuxt-ui-primary', color)
}

// We display all primary colors but last 2
const displayedPrimaryColors = ref(
  PrimaryColors.slice(0, PrimaryColors.length - 2)
)
</script>
