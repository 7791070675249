<template>
  <div
    class="relative mb-8 flex w-full flex-col items-center gap-10 overflow-hidden border-b border-block-3 text-center md:mb-40 md:border-b-0"
  >
    <ClientOnly>
      <HomeTetris with-gradient />
    </ClientOnly>

    <div class="container relative mx-auto flex flex-col items-center py-8">
      <NuxtLink
        :to="localePath('/changelog')"
        class="bg-primary-400/20 border-primary-400/10 mb-4 flex items-center rounded-md border px-1 py-0.5 backdrop-blur-sm lg:mb-6"
      >
        <UIcon
          name="i-heroicons-rocket-launch"
          class="dark:text-primary-400 text-primary-600 mr-1"
        />
        <span
          class="dark:text-primary-200 text-primary-800 t-medium__bold mr-1"
        >
          {{ t('c.newVersionOut') }}
        </span>
        <span class="dark:text-primary-200 text-primary-800 t-medium">{{
          t('c.checkWhatsNew')
        }}</span>
      </NuxtLink>
      <h1
        class="hero-heading t-heading-3 sm:t-heading-2 lg:t-heading-1 mb-10 text-balance"
      >
        {{ t('homePage.heroTitleLine1') }}
        <br />
        <span class="text-gradient font-semibold">
          {{ t('homePage.heroTitleLine2') }}
        </span>
      </h1>

      <span class="hero-subtitle t-subtitle mb-10 text-balance text-text-2">
        {{ t('homePage.heroDescription') }}
      </span>
      <div class="hero-btn">
        <UButton
          color="primary"
          size="lg"
          :to="appConfig.getStartedUrl"
        >
          {{ t('c.getStarted') }}
        </UButton>
      </div>
    </div>

    <UContainer class="hidden w-full flex-col items-center gap-8 md:flex">
      <div class="no-scrollbar flex gap-6 overflow-auto">
        <FeatureButton
          v-for="feature in features"
          :key="feature.text"
          :active="pickedFeature.id === feature.id"
          @click="pickedFeature = feature"
        >
          {{ feature.text }}
        </FeatureButton>
      </div>
      <div class="z-10 w-full rounded-3xl border border-block-3 bg-block-3 p-4">
        <div class="relative z-10 w-full rounded-2xl bg-bg">
          <div
            :class="imageLoaded && 'playing'"
            class="card-wrapper w-full rounded-2xl bg-block-3 shadow backdrop-blur"
          >
            <div class="card-content rounded-2xl">
              <ThemeImage
                sizes="100vw sm:50vw md:400px lg:1024px xl:1150px"
                width="1536"
                height="864"
                class="card-content rounded-2xl"
                :alt="pickedFeature.alt"
                :light="pickedFeature.imageLight"
                :dark="pickedFeature.imageDark"
                @load="imageLoaded = true"
              />
            </div>
          </div>
          <UButton
            :to="pickedFeature.to"
            size="xl"
            icon="i-j-arrow-right-typefull"
            trailing
            class="absolute bottom-3 left-1/2 -translate-x-1/2"
          >
            {{ t('c.learnMore') }}
          </UButton>
        </div>
      </div>
    </UContainer>

    <SvgSectionCloud
      class="pointer-events-none absolute top-1/4 z-0 h-1/2 w-2/3 bg-opacity-10"
    />
  </div>
</template>
<script setup lang="ts">
const { t } = useI18n()
const localePath = useLocalePath()
const appConfig = useAppConfig()

const imageLoaded = ref(false)

const features = [
  {
    text: t('homePage.featureDashboards'),
    id: 'dashboards',
    to: localePath('/features/dashboard'),
    alt: t('homePage.featureDashboards'),
    imageLight: '/img/homepage-dashboard-light.png',
    imageDark: '/img/homepage-dashboard-dark.png',
  },
  {
    text: t('homePage.featureTesting'),
    id: 'testing',
    to: localePath('/features/testing'),
    alt: t('homePage.featureTesting'),
    imageLight: '/img/homepage-test-light.png',
    imageDark: '/img/homepage-test-dark.png',
  },
  {
    text: t('homePage.featureRoadmaps'),
    id: 'roadmaps',
    to: localePath('/features/roadmap'),
    alt: t('homePage.featureRoadmaps'),
    imageLight: '/img/homepage-roadmaps-light.png',
    imageDark: '/img/homepage-roadmaps-dark.png',
  },
  {
    text: t('homePage.featureTimesheet'),
    id: 'time-tracking',
    to: localePath('/features/timesheet'),
    alt: t('homePage.featureTimesheet'),
    imageLight: '/img/homepage-timesheet-light.png',
    imageDark: '/img/homepage-timesheet-dark.png',
  },
  {
    text: t('homePage.featureDocs'),
    id: 'docs',
    to: localePath('/features/docs'),
    alt: t('homePage.featureDocs'),
    imageLight: '/img/homepage-docs-light.png',
    imageDark: '/img/homepage-docs-dark.png',
  },
  {
    text: t('homePage.featureIssueTracking'),
    id: 'issue-tracking',
    to: localePath('/features/issue'),
    alt: t('homePage.featureIssueTracking'),
    imageLight: '/img/homepage-issue-light.png',
    imageDark: '/img/homepage-issue-dark.png',
  },
]
const pickedFeature = ref(features[2])
</script>
<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0.2;
}

.hero-heading {
  animation: 800ms ease 0s 1 normal backwards running fadeIn;
}
.hero-subtitle {
  animation: 1000ms ease 300ms 1 normal backwards running fadeIn;
}

.hero-btn {
  animation: 1000ms ease 500ms 1 normal backwards running fadeIn;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
</style>
