<template>
  <video
    ref="videoRef"
    muted
    autoplay
    :loop="loop || false"
    :src="$isSafari ? undefined : activeSrc || undefined"
    :poster="posterSrc || undefined"
  ></video>
</template>
<script setup lang="ts">
const props = defineProps<{
  light: string
  dark: string
  posterLight: string
  posterDark: string
  loop?: boolean
}>()

const theme = useColorMode()

const src = computed(() => {
  return theme.value === 'light' ? props.light : props.dark
})

const posterSrc = computed(() => {
  return theme.value === 'light' ? props.posterLight : props.posterDark
})

const activeSrc = ref<string | null>(null)
const videoRef = ref<HTMLVideoElement | null>(null)
const { stop } = useIntersectionObserver(videoRef, ([{ isIntersecting }]) => {
  activeSrc.value = isIntersecting ? src.value : null

  if (isIntersecting) {
    stop()
  }
})

watch(theme, () => {
  activeSrc.value = activeSrc.value ? src.value : null
})

onUnmounted(() => {
  stop()
})
</script>
