<template>
  <div class="flex flex-col items-center justify-center gap-10">
    <div
      v-motion="{
        initial: {
          y: 100,
          opacity: 0.2,
        },
        visibleOnce: {
          y: 0,
          opacity: 1,
          transition: {
            type: 'spring',
            stiffness: 250,
            damping: 25,
            mass: 0.5,
          },
        },
      }"
      class="flex flex-col"
    >
      <h2
        class="t-heading-3-semi md:t-heading-2-sem lg:t-heading-1-semi mb-3 text-center text-text-1 lg:mb-6"
      >
        {{ t('homePage.useCasesTitle') }}
      </h2>
      <span class="t-subtitle text-center text-text-2">
        {{ t('homePage.useCasesSubtitle') }}
      </span>
    </div>

    <div
      class="mb-10 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-8 lg:grid-cols-4 xl:gap-10"
    >
      <div
        v-for="(item, key) in items"
        :key="key"
        v-motion="{
          initial: {
            y: 100,
            opacity: 0.2,
          },
          visibleOnce: {
            y: 0,
            opacity: 1,
            transition: {
              type: 'spring',
              stiffness: 250,
              damping: 25,
              mass: 0.5,
            },
          },
        }"
        class="flex max-w-sm flex-col items-center rounded-4xl border border-block-3 bg-block-1 px-5 pb-10 pt-5 xl:px-6"
      >
        <component
          :is="item.icon"
          class="mb-4 max-w-24 sm:max-w-60"
        />
        <span class="t-heading-4 mb-4 text-text-1">{{ item.title }}</span>
        <span class="t-body text-center text-text-2">
          {{ item.description }}
        </span>
      </div>
    </div>

    <div class="flex w-full flex-col items-center gap-8">
      <span class="t-medium text-text-2">{{
        t('c.trustedByBigCompanies')
      }}</span>
      <div
        v-motion="{
          initial: {
            y: 100,
            opacity: 0.2,
          },
          visibleOnce: {
            y: 0,
            opacity: 1,
            transition: {
              type: 'spring',
              stiffness: 250,
              damping: 25,
              mass: 0.5,
            },
          },
        }"
        class="grid w-full grid-cols-2 items-center justify-items-center gap-6 md:grid-cols-4 lg:grid-cols-8 lg:gap-12"
      >
        <img
          v-for="(image, key) in companyImages"
          :key="key"
          loading="lazy"
          :src="image.light"
          alt="Company logo"
          class="dark:hidden"
        />
        <img
          v-for="(image, key) in companyImages"
          :key="key"
          loading="lazy"
          :src="image.dark"
          alt="Company logo"
          class="hidden dark:block"
        />

        <!--   <ThemeImage
          v-for="(image, key) in companyImages"
          :key="key"
          alt="Company logo"
          class="flex-1"
          loading="lazy"
          :dark="image.dark"
          :light="image.light"
        /> -->
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import SvgAgile from '@/components/svg/Agile.vue'
import SvgMarketing from '@/components/svg/Marketing.vue'
import SvgTesting from '@/components/svg/Testing.vue'
import SvgBusiness from '@/components/svg/Business.vue'

const { t } = useI18n()

const items = [
  {
    icon: SvgAgile,
    title: t('homePage.useCasesAgileTitle'),
    description: t('homePage.useCasesAgileDesc'),
  },
  {
    icon: SvgMarketing,
    title: t('homePage.useCasesMarketingTitle'),
    description: t('homePage.useCasesMarketingDesc'),
  },
  {
    icon: SvgTesting,
    title: t('homePage.useCasesTestingTitle'),
    description: t('homePage.useCasesTestingDesc'),
  },
  {
    icon: SvgBusiness,
    title: t('homePage.useCasesBusinessTitle'),
    description: t('homePage.useCasesBusinessDesc'),
  },
]

const companyImages = [
  {
    light: '/img/companies/1-light.png',
    dark: '/img/companies/1-dark.png',
  },
  {
    light: '/img/companies/2-light.png',
    dark: '/img/companies/2-dark.png',
  },
  {
    light: '/img/companies/3-light.png',
    dark: '/img/companies/3-dark.png',
  },
  {
    light: '/img/companies/4-light.png',
    dark: '/img/companies/4-dark.png',
  },
  {
    light: '/img/companies/5-light.png',
    dark: '/img/companies/5-dark.png',
  },
  {
    light: '/img/companies/6-light.png',
    dark: '/img/companies/6-dark.png',
  },
  {
    light: '/img/companies/7-light.png',
    dark: '/img/companies/7-dark.png',
  },
  {
    light: '/img/companies/8-light.png',
    dark: '/img/companies/8-dark.png',
  },
]
</script>
