<template>
  <UPage>
    <UPageBody :ui="{ wrapper: 'pt-0 mt-0' }">
      <HeroSection class="md:pt-18" />

      <UContainer>
        <!-- <SectionsWithStickyImage class="py-32" /> -->
        <FeatureShowcase
          :title="t('c.homePageFeatureTitle')"
          :subtitle="t('c.homePageFeatureSubtitle')"
          :items="items"
          reverse
        />
        <WhatMakesUsCool class="py-10 lg:py-32" />
      </UContainer>
      <div class="overflow-hidden">
        <NumberOneSection class="pb-32 pt-16 lg:pt-32" />
        <UContainer>
          <FeatureCta
            class="pb-0 lg:pb-24"
            :title="t('c.homepageCtaTitle')"
            :description="t('c.homepageCtaDesc')"
          />
          <UseCasesSection class="py-32" />
          <JoinJunoToday class="w-full" />
        </UContainer>
      </div>
    </UPageBody>
  </UPage>
</template>
<script setup lang="ts">
const { t } = useI18n()

useSeo({
  title: '',
  titleOgImage: 'Juno.one',
  description: t('seo.homepageDesc'),
})

const items = [
  {
    id: 'testing',
    title: t('c.testing'),
    subtitle: t('c.homePageTestingTitle'),
    description: t('c.homePageTestingDesc'),
    videoDark: '/video/Testing-DARK.webm',
    videoLight: '/video/Testing-LIGHT.webm',
    posterDark: '/img/Testing-DARK-poster2x.png',
    posterLight: '/img/Testing-LIGHT-poster2x.png',
  },
  {
    id: 'planning',
    title: t('c.planning'),
    subtitle: t('c.homePagePlanningTitle'),
    description: t('c.homePagePlanningDesc'),
    videoDark: '/video/Roadmap-DARK.webm',
    videoLight: '/video/Roadmap-LIGHT.webm',
    posterDark: '/img/Roadmap-DARK-poster2x.png',
    posterLight: '/img/Roadmap-LIGHT-poster2x.png',
  },
  {
    id: 'reporting',
    title: t('c.reporting'),
    subtitle: t('c.homePageReportingTitle'),
    description: t('c.homePageReportingDesc'),
    videoDark: '/video/Reporting-DARK.webm',
    videoLight: '/video/Reporting-LIGHT.webm',
    posterDark: '/img/Reporting-DARK-poster2x.png',
    posterLight: '/img/Reporting-LIGHT-poster2x.png',
  },
]
</script>
