<template>
  <svg
    viewBox="0 0 210 195"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!--     width="210"
    height="195" -->
    <g filter="url(#filter0_bd_476_33832)">
      <circle
        cx="105"
        cy="104.548"
        r="80"
        fill="url(#paint0_linear_476_33832)"
        fill-opacity="0.03"
        shape-rendering="crispEdges"
      />
      <circle
        cx="105"
        cy="104.548"
        r="79.5"
        stroke="url(#paint1_linear_476_33832)"
        stroke-opacity="0.05"
        shape-rendering="crispEdges"
      />
    </g>
    <g filter="url(#filter1_b_476_33832)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M100 104.548C103.682 104.548 106.667 101.563 106.667 97.8815C106.667 94.1996 103.682 91.2148 100 91.2148C96.3181 91.2148 93.3334 94.1996 93.3334 97.8815C93.3334 101.563 96.3181 104.548 100 104.548ZM100 117.882C106.443 117.882 111.667 115.27 111.667 112.048C111.667 108.827 106.443 106.215 100 106.215C93.5567 106.215 88.3334 108.827 88.3334 112.048C88.3334 115.27 93.5567 117.882 100 117.882ZM106.037 103.626C107.46 102.131 108.334 100.108 108.334 97.8815C108.334 96.6205 108.053 95.4248 107.552 94.3534C108.349 93.9452 109.252 93.7148 110.209 93.7148C113.43 93.7148 116.042 96.3265 116.042 99.5482C116.042 102.77 113.43 105.382 110.209 105.382C108.574 105.382 107.096 104.709 106.037 103.626ZM113.333 112.048C113.333 113.972 112.045 115.727 109.928 117.055C110.021 117.056 110.115 117.057 110.208 117.057C115.846 117.057 120.417 114.771 120.417 111.952C120.417 109.133 115.846 106.848 110.208 106.848C110.012 106.848 109.816 106.851 109.622 106.856C111.921 108.204 113.333 110.033 113.333 112.048Z"
        fill="rgb(var(--color-primary-500))"
      />
    </g>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M61 100.548C63.2091 100.548 65 102.339 65 104.548C65 109.801 66.0346 115.003 68.0448 119.856C70.055 124.709 73.0014 129.118 76.7157 132.833C80.4301 136.547 84.8396 139.493 89.6927 141.504C94.5457 143.514 99.7471 144.548 105 144.548C107.209 144.548 109 146.339 109 148.548C109 150.757 107.209 152.548 105 152.548C98.6965 152.548 92.4548 151.307 86.6312 148.895C80.8076 146.482 75.5161 142.947 71.0589 138.489C66.6017 134.032 63.066 128.741 60.6538 122.917C58.2416 117.094 57 110.852 57 104.548C57 102.339 58.7909 100.548 61 100.548Z"
      fill="url(#paint2_linear_476_33832)"
      fill-opacity="0.3"
    />
    <path
      d="M61 100.948C62.9882 100.948 64.6 102.56 64.6 104.548C64.6 109.854 65.645 115.107 67.6753 120.009C69.7056 124.91 72.6814 129.364 76.4329 133.115C80.1844 136.867 84.638 139.843 89.5396 141.873C94.4411 143.903 99.6946 144.948 105 144.948C106.988 144.948 108.6 146.56 108.6 148.548C108.6 150.537 106.988 152.148 105 152.148C98.7491 152.148 92.5594 150.917 86.7843 148.525C81.0092 146.133 75.7618 142.627 71.3417 138.207C66.9216 133.787 63.4155 128.539 61.0233 122.764C58.6312 116.989 57.4 110.799 57.4 104.548C57.4 102.56 59.0118 100.948 61 100.948Z"
      stroke="url(#paint3_linear_476_33832)"
      stroke-opacity="0.08"
      stroke-width="0.8"
      stroke-linecap="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M149 100.548C151.209 100.548 153 102.339 153 104.548C153 110.852 151.758 117.094 149.346 122.917C146.934 128.741 143.398 134.032 138.941 138.489C134.484 142.947 129.192 146.482 123.369 148.895C117.545 151.307 111.303 152.548 105 152.548C102.791 152.548 101 150.757 101 148.548C101 146.339 102.791 144.548 105 144.548C110.253 144.548 115.454 143.514 120.307 141.504C125.16 139.493 129.57 136.547 133.284 132.833C136.999 129.118 139.945 124.709 141.955 119.856C143.965 115.003 145 109.801 145 104.548C145 102.339 146.791 100.548 149 100.548Z"
      fill="url(#paint4_linear_476_33832)"
      fill-opacity="0.3"
    />
    <path
      d="M149 100.948C150.988 100.948 152.6 102.56 152.6 104.548C152.6 110.799 151.369 116.989 148.977 122.764C146.585 128.539 143.078 133.787 138.658 138.207C134.238 142.627 128.991 146.133 123.216 148.525C117.441 150.917 111.251 152.148 105 152.148C103.012 152.148 101.4 150.537 101.4 148.548C101.4 146.56 103.012 144.948 105 144.948C110.305 144.948 115.559 143.903 120.46 141.873C125.362 139.843 129.816 136.867 133.567 133.115C137.319 129.364 140.294 124.91 142.325 120.009C144.355 115.107 145.4 109.854 145.4 104.548C145.4 102.56 147.012 100.948 149 100.948Z"
      stroke="url(#paint5_linear_476_33832)"
      stroke-opacity="0.08"
      stroke-width="0.8"
      stroke-linecap="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M101 60.5483C101 58.3392 102.791 56.5483 105 56.5483C111.303 56.5483 117.545 57.7899 123.369 60.2021C129.192 62.6143 134.484 66.15 138.941 70.6072C143.398 75.0644 146.934 80.3559 149.346 86.1795C151.758 92.0032 153 98.2449 153 104.548C153 106.757 151.209 108.548 149 108.548C146.791 108.548 145 106.757 145 104.548C145 99.2955 143.965 94.094 141.955 89.241C139.945 84.388 136.999 79.9784 133.284 76.2641C129.57 72.5497 125.16 69.6033 120.307 67.5932C115.454 65.583 110.253 64.5483 105 64.5483C102.791 64.5483 101 62.7575 101 60.5483Z"
      fill="url(#paint6_linear_476_33832)"
      fill-opacity="0.3"
    />
    <path
      d="M101.4 60.5483C101.4 58.5601 103.012 56.9483 105 56.9483C111.251 56.9483 117.441 58.1796 123.216 60.5717C128.991 62.9638 134.238 66.47 138.658 70.8901C143.078 75.3101 146.585 80.5575 148.977 86.3326C151.369 92.1077 152.6 98.2974 152.6 104.548C152.6 106.537 150.988 108.148 149 108.148C147.012 108.148 145.4 106.537 145.4 104.548C145.4 99.2429 144.355 93.9895 142.325 89.0879C140.294 84.1864 137.319 79.7327 133.567 75.9812C129.816 72.2297 125.362 69.2539 120.46 67.2236C115.559 65.1933 110.305 64.1483 105 64.1483C103.012 64.1483 101.4 62.5366 101.4 60.5483Z"
      stroke="url(#paint7_linear_476_33832)"
      stroke-opacity="0.08"
      stroke-width="0.8"
      stroke-linecap="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M105 64.5483C99.7471 64.5483 94.5457 65.583 89.6927 67.5932C84.8397 69.6033 80.4301 72.5497 76.7157 76.2641C73.0014 79.9784 70.055 84.388 68.0448 89.241C66.0346 94.094 65 99.2955 65 104.548C65 106.757 63.2091 108.548 61 108.548C58.7909 108.548 57 106.757 57 104.548C57 98.2449 58.2416 92.0032 60.6538 86.1795C63.066 80.3559 66.6017 75.0644 71.0589 70.6072C75.5161 66.15 80.8076 62.6143 86.6312 60.2021C92.4548 57.7899 98.6966 56.5483 105 56.5483C107.209 56.5483 109 58.3392 109 60.5483C109 62.7575 107.209 64.5483 105 64.5483Z"
      fill="url(#paint8_linear_476_33832)"
      fill-opacity="0.3"
    />
    <path
      d="M105 64.1483C99.6946 64.1483 94.4412 65.1933 89.5396 67.2236C84.638 69.2539 80.1844 72.2297 76.4329 75.9812C72.6814 79.7327 69.7056 84.1864 67.6753 89.0879C65.645 93.9895 64.6 99.2429 64.6 104.548C64.6 106.537 62.9882 108.148 61 108.148C59.0118 108.148 57.4 106.537 57.4 104.548C57.4 98.2974 58.6312 92.1077 61.0233 86.3326C63.4155 80.5575 66.9217 75.3101 71.3417 70.89C75.7618 66.47 81.0092 62.9638 86.7843 60.5717C92.5594 58.1795 98.7491 56.9483 105 56.9483C106.988 56.9483 108.6 58.5601 108.6 60.5483C108.6 62.5366 106.988 64.1483 105 64.1483Z"
      stroke="url(#paint9_linear_476_33832)"
      stroke-opacity="0.08"
      stroke-width="0.8"
      stroke-linecap="round"
    />
    <defs>
      <filter
        id="filter0_bd_476_33832"
        x="1"
        y="0.54834"
        width="208"
        height="208"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feGaussianBlur
          in="BackgroundImageFix"
          stdDeviation="12"
        />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_476_33832"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-8" />
        <feGaussianBlur stdDeviation="8" />
        <feComposite
          in2="hardAlpha"
          operator="out"
        />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_backgroundBlur_476_33832"
          result="effect2_dropShadow_476_33832"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_476_33832"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_b_476_33832"
        x="57"
        y="56.5483"
        width="96"
        height="96"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feGaussianBlur
          in="BackgroundImageFix"
          stdDeviation="12"
        />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_476_33832"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_476_33832"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_476_33832"
        x1="105"
        y1="24.5483"
        x2="105"
        y2="184.548"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--j-black)" />
        <stop
          offset="0.83"
          stop-color="var(--j-black)"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint1_linear_476_33832"
        x1="105"
        y1="24.5483"
        x2="105"
        y2="167.548"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--j-black)" />
        <stop
          offset="1"
          stop-color="var(--j-black)"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint2_linear_476_33832"
        x1="109"
        y1="152.548"
        x2="57"
        y2="100.548"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="rgb(var(--color-primary-400))" />
        <stop
          offset="1"
          stop-color="rgb(var(--color-primary-400))"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint3_linear_476_33832"
        x1="109"
        y1="152.548"
        x2="57"
        y2="100.548"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--j-black)" />
        <stop
          offset="0.435"
          stop-color="var(--j-black)"
          stop-opacity="0.4"
        />
        <stop
          offset="1"
          stop-color="var(--j-black)"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint4_linear_476_33832"
        x1="149"
        y1="104.548"
        x2="105"
        y2="148.548"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="rgb(var(--color-primary-400))" />
        <stop
          offset="1"
          stop-color="rgb(var(--color-primary-400))"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint5_linear_476_33832"
        x1="153"
        y1="100.548"
        x2="101"
        y2="152.548"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--j-black)" />
        <stop
          offset="0.435"
          stop-color="var(--j-black)"
          stop-opacity="0.4"
        />
        <stop
          offset="1"
          stop-color="var(--j-black)"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint6_linear_476_33832"
        x1="153"
        y1="108.548"
        x2="101"
        y2="56.5483"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stop-color="rgb(var(--color-primary-400))"
          stop-opacity="0"
        />
        <stop
          offset="1"
          stop-color="rgb(var(--color-primary-400))"
        />
      </linearGradient>
      <linearGradient
        id="paint7_linear_476_33832"
        x1="101"
        y1="56.5483"
        x2="153"
        y2="108.548"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--j-black)" />
        <stop
          offset="0.435"
          stop-color="var(--j-black)"
          stop-opacity="0.4"
        />
        <stop
          offset="1"
          stop-color="var(--j-black)"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint8_linear_476_33832"
        x1="105"
        y1="60.5483"
        x2="61"
        y2="104.548"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stop-color="rgb(var(--color-primary-400))"
          stop-opacity="0"
        />
        <stop
          offset="1"
          stop-color="rgb(var(--color-primary-400))"
        />
      </linearGradient>
      <linearGradient
        id="paint9_linear_476_33832"
        x1="57"
        y1="108.548"
        x2="109"
        y2="56.5483"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--j-black)" />
        <stop
          offset="0.435"
          stop-color="var(--j-black)"
          stop-opacity="0.4"
        />
        <stop
          offset="1"
          stop-color="var(--j-black)"
          stop-opacity="0"
        />
      </linearGradient>
    </defs>
  </svg>
</template>
