<template>
  <svg
    viewBox="0 0 210 195"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- width="210" height="195" -->
    <g filter="url(#filter0_bd_476_34024)">
      <circle
        cx="105"
        cy="104.548"
        r="80"
        fill="url(#paint0_linear_476_34024)"
        fill-opacity="0.03"
        shape-rendering="crispEdges"
      />
      <circle
        cx="105"
        cy="104.548"
        r="79.5"
        stroke="url(#paint1_linear_476_34024)"
        stroke-opacity="0.05"
        shape-rendering="crispEdges"
      />
    </g>
    <rect
      x="119"
      y="56.5483"
      width="32"
      height="32"
      rx="16"
      fill="var(--j-black)"
      fill-opacity="0.03"
    />
    <rect
      x="119.4"
      y="56.9483"
      width="31.2"
      height="31.2"
      rx="15.6"
      stroke="var(--j-black)"
      stroke-opacity="0.12"
      stroke-width="0.8"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M135 72.5483C137.485 72.5483 139.5 70.5336 139.5 68.0483C139.5 65.5631 137.485 63.5483 135 63.5483C132.515 63.5483 130.5 65.5631 130.5 68.0483C130.5 70.5336 132.515 72.5483 135 72.5483ZM143 77.5483C143 79.5036 140.194 81.1311 136.486 81.4794L135.624 77.1663C135.548 76.7887 135.696 76.4013 136.004 76.1702L136.78 75.5883C137.011 75.4153 136.888 75.0483 136.6 75.0483H133.4C133.112 75.0483 132.989 75.4154 133.22 75.5883L133.996 76.1702C134.304 76.4013 134.452 76.7887 134.376 77.1664L133.514 81.4794C129.806 81.1311 127 79.5036 127 77.5483C127 75.3392 130.582 73.5483 135 73.5483C139.418 73.5483 143 75.3392 143 77.5483Z"
      fill="var(--j-black)"
      fill-opacity="0.38"
    />
    <rect
      x="127"
      y="114.548"
      width="26"
      height="26"
      rx="13"
      fill="var(--j-black)"
      fill-opacity="0.03"
    />
    <rect
      x="127.4"
      y="114.948"
      width="25.2"
      height="25.2"
      rx="12.6"
      stroke="var(--j-black)"
      stroke-opacity="0.12"
      stroke-width="0.8"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M140 127.548C142.071 127.548 143.75 125.869 143.75 123.798C143.75 121.727 142.071 120.048 140 120.048C137.929 120.048 136.25 121.727 136.25 123.798C136.25 125.869 137.929 127.548 140 127.548ZM146.667 131.715C146.667 133.344 144.329 134.701 141.239 134.991L140.52 131.397C140.457 131.082 140.58 130.759 140.837 130.567L141.483 130.082C141.676 129.938 141.574 129.632 141.333 129.632H138.667C138.426 129.632 138.324 129.938 138.517 130.082L139.163 130.567C139.42 130.759 139.543 131.082 139.48 131.397L138.762 134.991C135.671 134.701 133.333 133.344 133.333 131.715C133.333 129.874 136.318 128.382 140 128.382C143.682 128.382 146.667 129.874 146.667 131.715Z"
      fill="var(--j-black)"
      fill-opacity="0.38"
    />
    <rect
      x="57"
      y="69.5483"
      width="26"
      height="26"
      rx="13"
      fill="var(--j-black)"
      fill-opacity="0.03"
    />
    <rect
      x="57.4"
      y="69.9483"
      width="25.2"
      height="25.2"
      rx="12.6"
      stroke="var(--j-black)"
      stroke-opacity="0.12"
      stroke-width="0.8"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M70 82.5483C72.0711 82.5483 73.75 80.8694 73.75 78.7983C73.75 76.7273 72.0711 75.0483 70 75.0483C67.929 75.0483 66.25 76.7273 66.25 78.7983C66.25 80.8694 67.929 82.5483 70 82.5483ZM76.6667 86.715C76.6667 88.3444 74.3287 89.7007 71.2386 89.9909L70.5197 86.3967C70.4568 86.082 70.5801 85.7591 70.8368 85.5666L71.4834 85.0817C71.6755 84.9375 71.5736 84.6317 71.3333 84.6317H68.6667C68.4264 84.6317 68.3245 84.9375 68.5167 85.0817L69.1632 85.5666C69.42 85.7591 69.5433 86.082 69.4804 86.3967L68.7615 89.9909C65.6714 89.7007 63.3334 88.3444 63.3334 86.715C63.3334 84.8741 66.3181 83.3817 70 83.3817C73.6819 83.3817 76.6667 84.8741 76.6667 86.715Z"
      fill="var(--j-black)"
      fill-opacity="0.38"
    />
    <g filter="url(#filter1_b_476_34024)">
      <rect
        x="66"
        y="104.548"
        width="48"
        height="48"
        rx="24"
        fill="url(#paint2_linear_476_34024)"
        fill-opacity="0.3"
      />
      <rect
        x="66.4"
        y="104.948"
        width="47.2"
        height="47.2"
        rx="23.6"
        stroke="url(#paint3_linear_476_34024)"
        stroke-opacity="0.08"
        stroke-width="0.8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M90 143.548C98.2843 143.548 105 136.833 105 128.548C105 120.264 98.2843 113.548 90 113.548C81.7157 113.548 75 120.264 75 128.548C75 136.833 81.7157 143.548 90 143.548ZM91.25 121.048C91.25 120.358 90.6904 119.798 90 119.798C89.3096 119.798 88.75 120.358 88.75 121.048V122.307C86.8871 122.439 85.4167 123.992 85.4167 125.888C85.4167 127.535 86.5378 128.971 88.1358 129.371L91.2578 130.151C91.743 130.273 92.0833 130.708 92.0833 131.209C92.0833 131.81 91.5954 132.298 90.9935 132.298H87.5C86.8096 132.298 86.25 132.858 86.25 133.548C86.25 134.239 86.8096 134.798 87.5 134.798H88.75V136.048C88.75 136.739 89.3096 137.298 90 137.298C90.6904 137.298 91.25 136.739 91.25 136.048V134.789C93.1129 134.658 94.5833 133.105 94.5833 131.209C94.5833 129.561 93.4622 128.125 91.8642 127.726L88.7422 126.945C88.257 126.824 87.9167 126.388 87.9167 125.888C87.9167 125.286 88.4046 124.798 89.0065 124.798H92.5C93.1904 124.798 93.75 124.239 93.75 123.548C93.75 122.858 93.1904 122.298 92.5 122.298H91.25V121.048Z"
        fill="rgb(var(--color-primary-500))"
      />
    </g>
    <path
      d="M118.5 73.5474L83.5 80.0479"
      stroke="var(--j-black)"
      stroke-opacity="0.12"
      stroke-dasharray="2 2"
    />
    <path
      d="M126.5 127.548L114.5 127.548"
      stroke="var(--j-black)"
      stroke-opacity="0.12"
      stroke-dasharray="2 2"
    />
    <path
      d="M126 86.5485L108 110.549"
      stroke="var(--j-black)"
      stroke-opacity="0.12"
      stroke-dasharray="2 2"
    />
    <path
      d="M73.4999 96.0482L78.5 106.048"
      stroke="var(--j-black)"
      stroke-opacity="0.12"
      stroke-dasharray="2 2"
    />
    <defs>
      <filter
        id="filter0_bd_476_34024"
        x="1"
        y="0.54834"
        width="208"
        height="208"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feGaussianBlur
          in="BackgroundImageFix"
          stdDeviation="12"
        />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_476_34024"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-8" />
        <feGaussianBlur stdDeviation="8" />
        <feComposite
          in2="hardAlpha"
          operator="out"
        />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_backgroundBlur_476_34024"
          result="effect2_dropShadow_476_34024"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_476_34024"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_b_476_34024"
        x="42"
        y="80.5483"
        width="96"
        height="96"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feGaussianBlur
          in="BackgroundImageFix"
          stdDeviation="12"
        />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_476_34024"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_476_34024"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_476_34024"
        x1="105"
        y1="24.5483"
        x2="105"
        y2="184.548"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--j-black)" />
        <stop
          offset="0.83"
          stop-color="var(--j-black)"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint1_linear_476_34024"
        x1="105"
        y1="24.5483"
        x2="105"
        y2="167.548"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--j-black)" />
        <stop
          offset="1"
          stop-color="var(--j-black)"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint2_linear_476_34024"
        x1="90"
        y1="104.548"
        x2="90"
        y2="152.548"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="rgb(var(--color-primary-400))" />
        <stop
          offset="0.84"
          stop-color="rgb(var(--color-primary-400))"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint3_linear_476_34024"
        x1="90"
        y1="104.548"
        x2="102.463"
        y2="149.059"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--j-black)" />
        <stop
          offset="0.13"
          stop-color="var(--j-black)"
          stop-opacity="0.1"
        />
        <stop
          offset="0.305"
          stop-color="var(--j-black)"
          stop-opacity="0.8"
        />
        <stop
          offset="0.565"
          stop-color="var(--j-black)"
          stop-opacity="0.1"
        />
        <stop
          offset="0.786809"
          stop-color="var(--j-black)"
          stop-opacity="0"
        />
        <stop
          offset="1"
          stop-color="var(--j-black)"
          stop-opacity="0"
        />
      </linearGradient>
    </defs>
  </svg>
</template>
