<template>
  <CtaCard
    v-motion="{
      initial: {
        y: 100,
        opacity: 0.2,
      },
      visibleOnce: {
        y: 0,
        opacity: 1,
        transition: {
          type: 'spring',
          stiffness: 250,
          damping: 25,
          mass: 0.5,
        },
      },
    }"
    class="grid md:grid-cols-2"
  >
    <div class="flex flex-col pb-8 md:pt-8">
      <span class="t-subtitle mb-2 text-center text-text-2 md:text-left">
        {{ t('c.readyToBoostWorkflow') }}
      </span>
      <span
        class="t-heading-3 mb-4 text-center font-semibold text-text-1 md:text-left"
      >
        {{ t('c.joinJunoToday') }}
      </span>
      <span class="text-center text-sm text-text-3 md:text-left">
        {{ t('c.freeForeverNoCredit') }}
      </span>
    </div>
    <div class="my-auto flex flex-col justify-start gap-4">
      <UForm
        :state="state"
        :validate="validate"
        class="flex flex-col gap-4"
        @submit="onSubmit"
      >
        <UFormGroup name="domain">
          <UInput
            v-model="state.domain"
            :placeholder="t('c.chooseDomain')"
          >
            <template #trailing>
              <span class="text-sm text-text-3">.juno.one</span>
            </template>
          </UInput>
        </UFormGroup>

        <UFormGroup name="email">
          <UInput
            v-model="state.email"
            :placeholder="t('c.workEmail')"
          />
        </UFormGroup>
        <UButton
          type="submit"
          color="primary"
          variant="solid"
          size="lg"
          class="justify-center md:justify-normal md:self-start"
        >
          {{ t('c.getStarted') }}
        </UButton>
      </UForm>
    </div>
  </CtaCard>
</template>
<script setup lang="ts">
const state = ref({
  domain: '',
  email: '',
  notifications: false,
})

const validate = (state: any): any[] => {
  const errors = []
  if (!state.email) errors.push({ path: 'email', message: t('c.required') })
  if (!state.domain) errors.push({ path: 'domain', message: t('c.required') })

  // VALIIDATE EMAIL
  if (state.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(state.email)) {
    errors.push({ path: 'email', message: t('c.invalidEmail') })
  }

  return errors
}
function onSubmit() {
  navigateTo(
    `${appConfig.getStartedUrl}?tenant=${state.value.domain}&email=${state.value.email}`,
    { external: true }
  )
}

const appConfig = useAppConfig()
const { t } = useI18n()
</script>
