<template>
  <div class="flex w-full flex-col">
    <svg
      class="robust-permissions-wrapper px-6"
      viewBox="0 0 261 180"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        class="pulsing-person-upper"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M74.6667 110C78.8088 110 82.1667 106.642 82.1667 102.5C82.1667 98.3579 78.8088 95 74.6667 95C70.5246 95 67.1667 98.3579 67.1667 102.5C67.1667 106.642 70.5246 110 74.6667 110ZM88 118.333C88 121.592 83.324 124.305 77.1438 124.885L75.7061 117.697C75.5802 117.067 75.8269 116.422 76.3403 116.036L77.6333 115.067C78.0177 114.778 77.8138 114.167 77.3333 114.167H72C71.5194 114.167 71.3155 114.778 71.7 115.067L72.9931 116.036C73.5066 116.422 73.7533 117.067 73.6274 117.697L72.1897 124.885C66.0095 124.305 61.3334 121.592 61.3334 118.333C61.3334 114.651 67.3029 111.667 74.6667 111.667C82.0305 111.667 88 114.651 88 118.333Z"
        fill="var(--j-black)"
        fill-opacity="0.12"
      />
      <path
        class="pulsing-person-upper"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M186.667 110C190.809 110 194.167 106.642 194.167 102.5C194.167 98.3579 190.809 95 186.667 95C182.525 95 179.167 98.3579 179.167 102.5C179.167 106.642 182.525 110 186.667 110ZM200 118.333C200 121.592 195.324 124.305 189.144 124.885L187.706 117.697C187.58 117.067 187.827 116.422 188.34 116.036L189.633 115.067C190.018 114.778 189.814 114.167 189.333 114.167H184C183.519 114.167 183.316 114.778 183.7 115.067L184.993 116.036C185.507 116.422 185.753 117.067 185.627 117.697L184.19 124.885C178.009 124.305 173.333 121.592 173.333 118.333C173.333 114.651 179.303 111.667 186.667 111.667C194.031 111.667 200 114.651 200 118.333Z"
        fill="var(--j-black)"
        fill-opacity="0.12"
      />

      <path
        id="pulse-path"
        d="M74.6667 84V49C74.6667 44.5817 78.2485 41 82.6667 41H87.5M186.667 84V49C186.667 44.5817 183.085 41 178.667 41H174.5"
        stroke="var(--j-black)"
        stroke-opacity="0.12"
        stroke-dasharray="4 4"
      />

      <path
        id="pulse-path-delayed"
        d="M42.6667 146V142C42.6667 137.582 46.2485 134 50.6667 134H98.6667C103.085 134 106.667 137.582 106.667 142V146"
        stroke="var(--j-black)"
        stroke-opacity="0.12"
        stroke-dasharray="4 4"
      />
      <path
        id="pulse-path-delayed"
        d="M154.667 146V142C154.667 137.582 158.248 134 162.667 134H210.667C215.085 134 218.667 137.582 218.667 142V146"
        stroke="var(--j-black)"
        stroke-opacity="0.12"
        stroke-dasharray="4 4"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        class="pulsing-person"
        d="M42.6667 164C45.152 164 47.1667 161.985 47.1667 159.5C47.1667 157.015 45.152 155 42.6667 155C40.1815 155 38.1667 157.015 38.1667 159.5C38.1667 161.985 40.1815 164 42.6667 164ZM42.6667 173C47.085 173 50.6667 171.209 50.6667 169C50.6667 166.791 47.085 165 42.6667 165C38.2485 165 34.6667 166.791 34.6667 169C34.6667 171.209 38.2485 173 42.6667 173Z"
        fill="var(--j-black)"
        fill-opacity="0.12"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        class="pulsing-person"
        d="M106.667 164C109.152 164 111.167 161.985 111.167 159.5C111.167 157.015 109.152 155 106.667 155C104.181 155 102.167 157.015 102.167 159.5C102.167 161.985 104.181 164 106.667 164ZM106.667 173C111.085 173 114.667 171.209 114.667 169C114.667 166.791 111.085 165 106.667 165C102.248 165 98.6667 166.791 98.6667 169C98.6667 171.209 102.248 173 106.667 173Z"
        fill="var(--j-black)"
        fill-opacity="0.12"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        class="pulsing-person"
        d="M154.667 164C157.152 164 159.167 161.985 159.167 159.5C159.167 157.015 157.152 155 154.667 155C152.181 155 150.167 157.015 150.167 159.5C150.167 161.985 152.181 164 154.667 164ZM154.667 173C159.085 173 162.667 171.209 162.667 169C162.667 166.791 159.085 165 154.667 165C150.248 165 146.667 166.791 146.667 169C146.667 171.209 150.248 173 154.667 173Z"
        fill="var(--j-black)"
        fill-opacity="0.12"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        class="pulsing-person"
        d="M218.667 164C221.152 164 223.167 161.985 223.167 159.5C223.167 157.015 221.152 155 218.667 155C216.181 155 214.167 157.015 214.167 159.5C214.167 161.985 216.181 164 218.667 164ZM218.667 173C223.085 173 226.667 171.209 226.667 169C226.667 166.791 223.085 165 218.667 165C214.248 165 210.667 166.791 210.667 169C210.667 171.209 214.248 173 218.667 173Z"
        fill="var(--j-black)"
        fill-opacity="0.12"
      />

      <g filter="url(#filter0_b_138_118487)">
        <circle
          cx="131"
          cy="40"
          r="40"
          fill="url(#paint2_linear_138_118487)"
          fill-opacity="0.03"
        />
        <circle
          cx="131"
          cy="40"
          r="40"
          fill="url(#paint3_linear_138_118487)"
          fill-opacity="0.1"
        />
        <circle
          cx="131"
          cy="40"
          r="39.5"
          stroke="url(#paint4_linear_138_118487)"
          stroke-opacity="0.12"
        />
      </g>
      <path
        d="M149.667 51.6666C149.667 56.8212 141.309 60.9999 131 60.9999C120.691 60.9999 112.333 56.8212 112.333 51.6666C112.333 46.5119 120.691 42.3333 131 42.3333C141.309 42.3333 149.667 46.5119 149.667 51.6666Z"
        fill="rgb(var(--color-primary-500))"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M120.516 30.0833C120.505 29.8902 120.5 29.6957 120.5 29.5L120.5 20.6176C120.5 19.8213 121.275 19.2726 122.017 19.5626C123.89 20.295 126.333 21.3333 126.333 21.3333L129.957 19.5217C130.613 19.1933 131.387 19.1933 132.043 19.5217L135.667 21.3333C135.667 21.3333 138.02 20.3137 139.98 19.5557C140.723 19.2685 141.5 19.8212 141.5 20.6176L141.5 29.5C141.5 29.5159 141.5 29.5318 141.5 29.5478C141.499 29.7274 141.494 29.9059 141.484 30.0833C141.181 35.611 136.603 40 131 40C125.397 40 120.819 35.611 120.516 30.0833ZM124.024 30.0833H137.976C137.68 33.6764 134.67 36.5 131 36.5C127.33 36.5 124.32 33.6764 124.024 30.0833Z"
        fill="rgb(var(--color-primary-500))"
      />
      <defs>
        <filter
          id="filter0_b_138_118487"
          x="67"
          y="-24"
          width="128"
          height="128"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood
            flood-opacity="0"
            result="BackgroundImageFix"
          />
          <feGaussianBlur
            in="BackgroundImageFix"
            stdDeviation="12"
          />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_138_118487"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_138_118487"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_138_118487"
          x1="5.99976"
          y1="240"
          x2="30.9998"
          y2="240"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            offset="0.12"
            stop-color="var(--j-block-4)"
          />
          <stop
            offset="1"
            stop-color="var(--j-block-4)"
            stop-opacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint1_linear_138_118487"
          x1="255"
          y1="260"
          x2="280"
          y2="260"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            offset="0.12"
            stop-color="var(--j-block-4)"
          />
          <stop
            offset="1"
            stop-color="var(--j-block-4)"
            stop-opacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint2_linear_138_118487"
          x1="131"
          y1="0"
          x2="131"
          y2="68.0556"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--j-black)" />
          <stop
            offset="0.84"
            stop-color="var(--j-black)"
            stop-opacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint3_linear_138_118487"
          x1="131"
          y1="0"
          x2="131"
          y2="80"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="rgb(var(--color-primary-500))" />
          <stop
            offset="0.84"
            stop-color="rgb(var(--color-primary-900))"
            stop-opacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint4_linear_138_118487"
          x1="131"
          y1="-9.27184e-07"
          x2="151.772"
          y2="74.184"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--j-black)" />
          <stop
            offset="0.13"
            stop-color="var(--j-black)"
            stop-opacity="0.1"
          />
          <stop
            offset="0.305"
            stop-color="var(--j-black)"
            stop-opacity="0.8"
          />
          <stop
            offset="0.565"
            stop-color="var(--j-black)"
            stop-opacity="0.1"
          />
          <stop
            offset="0.786809"
            stop-color="var(--j-black)"
            stop-opacity="0"
          />
          <stop
            offset="1"
            stop-color="var(--j-black)"
            stop-opacity="0"
          />
        </linearGradient>
      </defs>
    </svg>

    <div class="slider-container relative h-24 w-full">
      <div
        id="slider-wrapper"
        ref="sliderWrapper"
        class="absolute flex h-24 items-center gap-2"
      >
        <div
          v-for="(item, key) in [...items, ...items]"
          :key="key"
          class="slider-wrapper-item flex items-center gap-1 rounded-md bg-block-3 p-1 text-text-3"
        >
          <UIcon :name="item.icon" />
          <span class="t-medium">{{ item.text }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
const { $gsap: gsap } = useNuxtApp()

onMounted(() => {
  const tl = gsap.timeline({
    repeat: -1,
    repeatDelay: 1,
    yoyo: true,
    ease: 'power2.inOut',
    defaults: { duration: 0.7 },
    scrollTrigger: {
      trigger: '.robust-permissions-wrapper',
      markers: false,
      toggleActions: 'play pause resume pause',
    },
  })
  tl.to('#pulse-path', { strokeOpacity: 0.2, strokeWidth: 2 }, '-=50%')
    .to('.pulsing-person-upper', { fillOpacity: 0.5 }, '-=50%')
    .to('#pulse-path-delayed', { strokeOpacity: 0.2, strokeWidth: 2 }, '-=50%')
    .to('.pulsing-person', { fillOpacity: 0.5 }, '-=50%')

  onUnmounted(() => {
    tl.kill()
  })
})

const { t } = useI18n()

const items = [
  {
    icon: 'i-j-visible-outline',
    text: t('c.permissionView'),
  },
  {
    icon: 'i-j-edit-outline',
    text: t('c.permissionEdit'),
  },
  {
    icon: 'i-j-delete-outline',
    text: t('c.permissionDelete'),
  },
  {
    icon: 'i-j-visible-outline',
    text: t('c.permissionRestore'),
  },
  {
    icon: 'i-j-archive-outline',
    text: t('c.permissionArchive'),
  },
  {
    icon: 'i-j-lock-outline',
    text: t('c.permissionLock'),
  },
]
</script>
<style lang="scss" scoped></style>
<style lang="scss">
#slider-wrapper {
  will-change: transform;
  animation: marquee-horizontal 16s linear infinite;
}

@keyframes marquee-horizontal {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
</style>
