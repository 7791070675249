<template>
  <svg
    class="multilingual-wrapper"
    viewBox="0 0 261 260"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      class="czech origin-center"
      clip-path="url(#clip0_479_34828)"
    >
      <path
        d="M130.333 165.75C150.077 165.75 166.083 149.744 166.083 130C166.083 110.256 150.077 94.25 130.333 94.25C110.589 94.25 94.5833 110.256 94.5833 130C94.5833 149.744 110.589 165.75 130.333 165.75Z"
        fill="#F0F0F0"
      />
      <path
        d="M127.225 130C127.225 130 105.075 155.284 105.054 155.279C111.523 161.749 120.461 165.75 130.333 165.75C150.077 165.75 166.083 149.744 166.083 130H127.225Z"
        fill="#D80027"
      />
      <path
        d="M105.054 104.721C91.0928 118.682 91.0928 141.318 105.054 155.279C110.823 149.51 116.372 143.961 130.333 130L105.054 104.721Z"
        fill="#0052B4"
      />
      <circle
        cx="130.333"
        cy="130"
        r="35.25"
        fill="url(#paint0_linear_479_34828)"
      />
      <circle
        cx="130.333"
        cy="130"
        r="35.25"
        stroke="url(#paint1_linear_479_34828)"
        style="mix-blend-mode: overlay"
      />
    </g>
    <g
      class="england"
      clip-path="url(#clip1_479_34828)"
    >
      <path
        d="M213.208 92.625C231.158 92.625 245.708 78.0743 245.708 60.125C245.708 42.1757 231.158 27.625 213.208 27.625C195.259 27.625 180.708 42.1757 180.708 60.125C180.708 78.0743 195.259 92.625 213.208 92.625Z"
        fill="#F0F0F0"
      />
      <path
        d="M187.427 40.3384C184.874 43.6599 182.949 47.4881 181.828 51.647H198.735L187.427 40.3384Z"
        fill="#0052B4"
      />
      <path
        d="M244.589 51.6469C243.468 47.4881 241.543 43.6599 238.99 40.3384L227.682 51.6469H244.589Z"
        fill="#0052B4"
      />
      <path
        d="M181.828 68.6035C182.949 72.7622 184.874 76.5905 187.427 79.9119L198.735 68.6035H181.828Z"
        fill="#0052B4"
      />
      <path
        d="M232.995 34.3435C229.674 31.7906 225.845 29.8656 221.687 28.7446V45.6519L232.995 34.3435Z"
        fill="#0052B4"
      />
      <path
        d="M193.422 85.9066C196.743 88.4595 200.571 90.3845 204.73 91.5055V74.5984L193.422 85.9066Z"
        fill="#0052B4"
      />
      <path
        d="M204.73 28.7446C200.571 29.8656 196.743 31.7906 193.422 34.3434L204.73 45.6517V28.7446Z"
        fill="#0052B4"
      />
      <path
        d="M221.687 91.5055C225.845 90.3845 229.674 88.4595 232.995 85.9067L221.687 74.5984V91.5055Z"
        fill="#0052B4"
      />
      <path
        d="M227.682 68.6035L238.99 79.912C241.543 76.5906 243.468 72.7622 244.589 68.6035H227.682Z"
        fill="#0052B4"
      />
      <path
        d="M245.433 55.8859H217.448H217.447V27.9001C216.06 27.7195 214.645 27.625 213.208 27.625C211.771 27.625 210.357 27.7195 208.969 27.9001V55.8857V55.8858H180.983C180.803 57.2735 180.708 58.6883 180.708 60.125C180.708 61.562 180.803 62.9765 180.983 64.3641H208.969H208.969V92.3499C210.357 92.5305 211.771 92.625 213.208 92.625C214.645 92.625 216.06 92.5307 217.447 92.3499V64.3643V64.3642H245.433C245.614 62.9765 245.708 61.562 245.708 60.125C245.708 58.6883 245.614 57.2735 245.433 55.8859Z"
        fill="#D80027"
      />
      <path
        d="M221.687 68.6034L236.189 83.106C236.856 82.4393 237.492 81.7422 238.1 81.0195L225.683 68.6033H221.687V68.6034Z"
        fill="#D80027"
      />
      <path
        d="M204.73 68.6035H204.73L190.227 83.106C190.894 83.773 191.591 84.4093 192.314 85.0164L204.73 72.5999V68.6035Z"
        fill="#D80027"
      />
      <path
        d="M204.73 51.647V51.6468L190.227 37.144C189.56 37.8108 188.924 38.5079 188.317 39.2305L200.733 51.6469L204.73 51.647Z"
        fill="#D80027"
      />
      <path
        d="M221.687 51.647L236.189 37.1442C235.523 36.4771 234.825 35.8408 234.103 35.2339L221.687 47.6503V51.647Z"
        fill="#D80027"
      />
      <circle
        cx="213.208"
        cy="60.125"
        r="32.5"
        fill="url(#paint2_linear_479_34828)"
      />
    </g>
    <g
      class="spain origin-center"
      clip-path="url(#clip2_479_34828)"
    >
      <path
        d="M89.7083 29.2499C89.7083 32.0327 90.2088 34.6984 91.1233 37.1629L112.458 39.1412L133.793 37.1629C134.708 34.6984 135.208 32.0327 135.208 29.2499C135.208 26.4671 134.708 23.8015 133.793 21.3369L112.458 19.3586L91.1233 21.3369C90.2088 23.8015 89.7083 26.4671 89.7083 29.2499Z"
        fill="#FFDA44"
      />
      <path
        d="M133.793 21.337C130.579 12.6739 122.24 6.5 112.458 6.5C102.677 6.5 94.3377 12.6739 91.1233 21.337H133.793Z"
        fill="#D80027"
      />
      <path
        d="M91.1233 37.1631C94.3377 45.8262 102.677 52.0001 112.458 52.0001C122.24 52.0001 130.579 45.8262 133.793 37.1631H91.1233Z"
        fill="#D80027"
      />
      <circle
        cx="112.458"
        cy="29.25"
        r="22.75"
        fill="url(#paint3_linear_479_34828)"
      />
    </g>
    <g
      class="poland origin-center"
      clip-path="url(#clip3_479_34828)"
    >
      <rect
        x="205.083"
        y="131.625"
        width="32.5"
        height="32.5"
        fill="#F0F0F0"
      />
      <rect
        x="205.083"
        y="147.875"
        width="32.5"
        height="16.25"
        fill="#ED1F34"
      />
      <circle
        cx="221.333"
        cy="147.875"
        r="16.25"
        fill="url(#paint4_linear_479_34828)"
      />
    </g>
    <g class="slovakia">
      <path
        d="M175.833 199.875C165.899 199.875 157.482 206.245 154.373 215.042H197.294C194.185 206.245 185.768 199.875 175.833 199.875Z"
        fill="#F9F9F9"
      />
      <path
        d="M175.833 245.375C185.768 245.375 194.185 239.081 197.294 230.208H154.373C157.482 239.081 165.899 245.375 175.833 245.375Z"
        fill="#ED4C5C"
      />
      <path
        d="M154.372 215.042C153.538 217.393 153.083 219.971 153.083 222.625C153.083 225.279 153.538 227.858 154.372 230.208H197.294C198.128 227.858 198.583 225.279 198.583 222.625C198.583 219.971 198.128 217.393 197.294 215.042H154.372Z"
        fill="#0052B4"
      />
      <path
        d="M177.274 212.767H162.183V223.232C162.183 230.208 169.463 232.332 169.766 232.483C170.07 232.332 177.35 230.208 177.35 223.232V212.767H177.274Z"
        fill="#F9F9F9"
      />
      <path
        d="M163.7 214.283V223.308C163.7 227.554 166.961 229.829 169.766 230.967C172.572 229.829 175.833 227.554 175.833 223.308V214.283H163.7Z"
        fill="#ED4C5C"
      />
      <path
        d="M169.008 215.8H170.525V226.417H169.008V215.8Z"
        fill="#F9F9F9"
      />
      <path
        d="M165.975 220.35H173.558V221.867H165.975V220.35Z"
        fill="#F9F9F9"
      />
      <path
        d="M167.492 217.317H172.042V218.833H167.492V217.317Z"
        fill="#F9F9F9"
      />
      <path
        d="M169.767 224.9C168.781 224.9 167.871 225.355 167.34 226.113C166.961 225.81 166.506 225.658 165.975 225.658C165.368 225.658 164.762 225.886 164.383 226.341C165.444 228.692 167.719 230.132 169.767 230.967C171.814 230.132 174.089 228.692 175.151 226.341C174.772 225.886 174.165 225.658 173.558 225.658C173.028 225.658 172.573 225.81 172.193 226.113C171.663 225.355 170.753 224.9 169.767 224.9Z"
        fill="#0052B4"
      />
      <circle
        cx="175.833"
        cy="222.625"
        r="22.75"
        fill="url(#paint5_linear_479_34828)"
      />
    </g>
    <g
      class="france"
      clip-path="url(#clip4_479_34828)"
    >
      <path
        d="M49.0833 82.875C58.0579 82.875 65.3333 75.5996 65.3333 66.625C65.3333 57.6504 58.0579 50.375 49.0833 50.375C40.1086 50.375 32.8333 57.6504 32.8333 66.625C32.8333 75.5996 40.1086 82.875 49.0833 82.875Z"
        fill="#F0F0F0"
      />
      <path
        d="M65.3332 66.625C65.3332 59.6381 60.9234 53.6818 54.7354 51.3857V81.8643C60.9234 79.5683 65.3332 73.6119 65.3332 66.625Z"
        fill="#D80027"
      />
      <path
        d="M32.8333 66.625C32.8333 73.6119 37.2432 79.5683 43.4311 81.8643V51.3857C37.2432 53.6818 32.8333 59.6381 32.8333 66.625Z"
        fill="#0052B4"
      />
      <circle
        cx="49.0833"
        cy="66.625"
        r="16.25"
        fill="url(#paint6_linear_479_34828)"
      />
    </g>
    <g
      class="germany"
      clip-path="url(#clip5_479_34828)"
    >
      <path
        d="M34.4504 215.418C38.1241 225.319 47.6542 232.375 58.8333 232.375C70.0123 232.375 79.5425 225.319 83.2161 215.418L58.8333 213.158L34.4504 215.418Z"
        fill="#FFDA44"
      />
      <path
        d="M58.8333 180.375C47.6542 180.375 38.1241 187.431 34.4504 197.332L58.8333 199.592L83.2161 197.331C79.5425 187.431 70.0123 180.375 58.8333 180.375Z"
        fill="black"
      />
      <path
        d="M34.4504 197.332C33.4054 200.148 32.8333 203.195 32.8333 206.375C32.8333 209.555 33.4054 212.602 34.4504 215.418H83.2162C84.2613 212.602 84.8333 209.555 84.8333 206.375C84.8333 203.195 84.2613 200.148 83.2161 197.332H34.4504Z"
        fill="#D80027"
      />
      <circle
        cx="58.8333"
        cy="206.375"
        r="26"
        fill="url(#paint7_linear_479_34828)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_479_34828"
        x1="94.5833"
        y1="94.25"
        x2="166.083"
        y2="165.75"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-opacity="0" />
        <stop
          offset="1"
          stop-opacity="0.2"
        />
      </linearGradient>
      <linearGradient
        id="paint1_linear_479_34828"
        x1="94.5833"
        y1="94.25"
        x2="166.083"
        y2="165.75"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stop-color="white"
          stop-opacity="0"
        />
        <stop
          offset="0.494792"
          stop-color="white"
          stop-opacity="0.38"
        />
        <stop
          offset="1"
          stop-color="white"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint2_linear_479_34828"
        x1="180.708"
        y1="27.625"
        x2="245.708"
        y2="92.625"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stop-color="white"
          stop-opacity="0"
        />
        <stop
          offset="1"
          stop-color="white"
          stop-opacity="0.2"
        />
      </linearGradient>
      <linearGradient
        id="paint3_linear_479_34828"
        x1="89.7083"
        y1="6.5"
        x2="135.208"
        y2="52"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stop-color="white"
          stop-opacity="0"
        />
        <stop
          offset="1"
          stop-color="white"
          stop-opacity="0.2"
        />
      </linearGradient>
      <linearGradient
        id="paint4_linear_479_34828"
        x1="205.083"
        y1="131.625"
        x2="237.583"
        y2="164.125"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stop-color="white"
          stop-opacity="0"
        />
        <stop
          offset="1"
          stop-color="white"
          stop-opacity="0.2"
        />
      </linearGradient>
      <linearGradient
        id="paint5_linear_479_34828"
        x1="153.083"
        y1="199.875"
        x2="198.583"
        y2="245.375"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stop-color="white"
          stop-opacity="0"
        />
        <stop
          offset="1"
          stop-color="white"
          stop-opacity="0.2"
        />
      </linearGradient>
      <linearGradient
        id="paint6_linear_479_34828"
        x1="32.8333"
        y1="50.375"
        x2="65.3333"
        y2="82.875"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stop-color="white"
          stop-opacity="0"
        />
        <stop
          offset="1"
          stop-color="white"
          stop-opacity="0.2"
        />
      </linearGradient>
      <linearGradient
        id="paint7_linear_479_34828"
        x1="32.8333"
        y1="180.375"
        x2="84.8333"
        y2="232.375"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stop-color="white"
          stop-opacity="0"
        />
        <stop
          offset="1"
          stop-color="white"
          stop-opacity="0.2"
        />
      </linearGradient>
      <clipPath id="clip0_479_34828">
        <rect
          x="94.5833"
          y="94.25"
          width="71.5"
          height="71.5"
          rx="35.75"
          fill="white"
        />
      </clipPath>
      <clipPath id="clip1_479_34828">
        <rect
          x="180.708"
          y="27.625"
          width="65"
          height="65"
          rx="32.5"
          fill="white"
        />
      </clipPath>
      <clipPath id="clip2_479_34828">
        <rect
          x="89.7083"
          y="6.5"
          width="45.5"
          height="45.5"
          rx="22.75"
          fill="white"
        />
      </clipPath>
      <clipPath id="clip3_479_34828">
        <rect
          x="205.083"
          y="131.625"
          width="32.5"
          height="32.5"
          rx="16.25"
          fill="white"
        />
      </clipPath>
      <clipPath id="clip4_479_34828">
        <rect
          x="32.8333"
          y="50.375"
          width="32.5"
          height="32.5"
          rx="16.25"
          fill="white"
        />
      </clipPath>
      <clipPath id="clip5_479_34828">
        <rect
          x="32.8333"
          y="180.375"
          width="52"
          height="52"
          rx="26"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script setup lang="ts">
const { $gsap: gsap } = useNuxtApp()

let tl: null | gsap.core.Timeline = null

onMounted(() => {
  tl = gsap.timeline({
    defaults: { duration: 0.8 },
    repeat: -1,
    repeatDelay: 0.8,
    scrollTrigger: {
      trigger: '.multilingual-wrapper',
      markers: false,
      toggleActions: 'play pause resume pause',
    },
  })

  tl.to('.poland', {
    scale: 1.2,
    duration: 1.5,
    transformOrigin: 'center',
  })
    .to(
      '.poland',
      {
        scale: 1.0,
        duration: 1.5,
        transformOrigin: 'center',
      },
      2
    )
    .to(
      '.czech',
      {
        scale: 0.8,
        rotate: 45,
        duration: 1.5,
        transformOrigin: 'center',
      },
      1.1
    )
    .to(
      '.czech',
      {
        scale: 1,
        rotate: 0,
        duration: 1.5,

        transformOrigin: 'center',
      },
      3.5
    )
    .to(
      '.england',
      {
        rotate: 90,
        duration: 1.5,
        transformOrigin: 'center',
      },
      1.4
    )
    .to(
      '.spain',
      {
        scale: 1.2,
        duration: 1.5,
        transformOrigin: 'center',
      },
      0.3
    )
    .to(
      '.spain',
      {
        scale: 1,
        duration: 1.5,
        transformOrigin: 'center',
      },
      3.8
    )
    .to(
      '.france',
      {
        scale: 1.2,
        duration: 1.5,
        transformOrigin: 'center',
      },
      1.5
    )
    .to(
      '.france',
      {
        scale: 1,
        duration: 1.5,
        transformOrigin: 'center',
      },
      4.2
    )
    .to(
      '.germany',
      {
        rotate: 360,
        duration: 2,
        transformOrigin: 'center',
      },
      0
    )
    .to(
      '.slovakia',
      {
        scale: 1.2,
        duration: 1.5,
        transformOrigin: 'center',
      },
      0.8
    )
    .to(
      '.slovakia',
      {
        scale: 1.0,
        duration: 1.5,
        transformOrigin: 'center',
      },
      3.5
    )

  onUnmounted(() => {
    tl?.kill()
  })
})
</script>
