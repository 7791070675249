<template>
  <svg
    viewBox="0 0 210 195"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- width="210" height="195"  -->
    <g filter="url(#filter0_bd_476_33906)">
      <circle
        cx="105"
        cy="104.548"
        r="80"
        fill="url(#paint0_linear_476_33906)"
        fill-opacity="0.03"
        shape-rendering="crispEdges"
      />
      <circle
        cx="105"
        cy="104.548"
        r="79.5"
        stroke="url(#paint1_linear_476_33906)"
        stroke-opacity="0.05"
        shape-rendering="crispEdges"
      />
    </g>
    <g filter="url(#filter1_b_476_33906)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M63 80.5483C66.3137 80.5483 69 77.862 69 74.5483C69 71.2346 66.3137 68.5483 63 68.5483C59.6863 68.5483 57 71.2346 57 74.5483C57 77.862 59.6863 80.5483 63 80.5483ZM65.6869 73.2352C65.8821 73.04 65.8821 72.7234 65.6869 72.5281C65.4916 72.3329 65.175 72.3329 64.9798 72.5281L62 75.5079L61.0202 74.5281C60.825 74.3329 60.5084 74.3329 60.3131 74.5281C60.1179 74.7234 60.1179 75.04 60.3131 75.2352L61.5522 76.4743C61.7995 76.7216 62.2005 76.7216 62.4478 76.4743L65.6869 73.2352Z"
        fill="#68C161"
      />
      <g filter="url(#filter2_b_476_33906)">
        <rect
          x="75"
          y="70.5483"
          width="76"
          height="8"
          rx="4"
          fill="url(#paint2_linear_476_33906)"
          fill-opacity="0.3"
        />
        <rect
          x="75.4"
          y="70.9483"
          width="75.2"
          height="7.2"
          rx="3.6"
          stroke="url(#paint3_linear_476_33906)"
          stroke-opacity="0.08"
          stroke-width="0.8"
        />
      </g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M69 94.5483C69 97.862 66.3137 100.548 63 100.548C59.6863 100.548 57 97.862 57 94.5483C57 91.2346 59.6863 88.5483 63 88.5483C66.3137 88.5483 69 91.2346 69 94.5483ZM60.9965 92.5449C61.1918 92.3496 61.5084 92.3496 61.7037 92.5449L63 93.8412L64.2964 92.5449C64.4916 92.3496 64.8082 92.3496 65.0035 92.5449C65.1987 92.7401 65.1987 93.0567 65.0035 93.252L63.7071 94.5483L65.0035 95.8447C65.1987 96.04 65.1987 96.3566 65.0035 96.5518C64.8082 96.7471 64.4916 96.7471 64.2964 96.5518L63 95.2554L61.7036 96.5518C61.5084 96.7471 61.1918 96.7471 60.9965 96.5518C60.8013 96.3565 60.8013 96.04 60.9965 95.8447L62.2929 94.5483L60.9965 93.252C60.8013 93.0567 60.8013 92.7401 60.9965 92.5449Z"
        fill="#E35D6A"
      />
      <g filter="url(#filter3_b_476_33906)">
        <rect
          x="75"
          y="90.5483"
          width="76"
          height="8"
          rx="4"
          fill="url(#paint4_linear_476_33906)"
          fill-opacity="0.3"
        />
        <rect
          x="75.4"
          y="90.9483"
          width="75.2"
          height="7.2"
          rx="3.6"
          stroke="url(#paint5_linear_476_33906)"
          stroke-opacity="0.08"
          stroke-width="0.8"
        />
      </g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M147.077 84.7835C148.509 83.3516 150.796 83.3165 152.184 84.705L153.098 85.6192C154.487 87.0078 154.452 89.2942 153.02 90.7261C152.109 91.6373 150.654 91.6597 149.77 90.7761L147.027 88.0334C146.144 87.1498 146.166 85.6948 147.077 84.7835ZM144.067 88.0276C144.978 88.2585 145.835 88.7266 146.542 89.4333L148.37 91.2618C149.077 91.9685 149.545 92.8257 149.776 93.7367L155.064 93.6554C155.607 93.647 156.042 94.0811 156.033 94.6251C156.025 95.169 155.577 95.6167 155.033 95.6251L149.912 95.7039C149.81 96.645 149.471 97.5718 148.896 98.3868L151.013 100.504C151.392 100.883 151.382 101.506 150.991 101.897C150.601 102.287 149.977 102.297 149.599 101.918L147.542 99.8614L147.306 100.097C146.316 101.087 145.111 101.728 143.852 102.016L143.791 105.953C143.783 106.497 143.335 106.945 142.791 106.953C142.247 106.961 141.813 106.527 141.821 105.983L141.88 102.18C140.613 102.104 137.942 101.275 139.206 100.011C140.47 98.7475 141.826 97.392 141.826 97.392L145.335 93.8831C145.725 93.4926 145.725 92.8594 145.335 92.4689C144.944 92.0783 144.311 92.0783 143.92 92.4689L140.411 95.9777C140.411 95.9777 139.153 97.2363 137.792 98.5971C136.431 99.958 135.7 97.1901 135.624 95.9237L131.82 95.9822C131.276 95.9906 130.842 95.5564 130.85 95.0125C130.859 94.4686 131.307 94.0209 131.85 94.0125L135.788 93.9519C136.076 92.6924 136.716 91.4879 137.706 90.4975L137.942 90.2618L135.885 88.2048C135.506 87.8261 135.516 87.2025 135.906 86.812C136.297 86.4215 136.921 86.4119 137.299 86.7906L139.417 88.9079C140.232 88.3329 141.159 87.9936 142.1 87.8916L142.178 82.7703C142.187 82.2263 142.635 81.7786 143.178 81.7703C143.722 81.7619 144.157 82.196 144.148 82.74L144.067 88.0276Z"
        fill="rgb(var(--color-primary-500))"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M63 120.548C66.3137 120.548 69 117.862 69 114.548C69 111.235 66.3137 108.548 63 108.548C59.6863 108.548 57 111.235 57 114.548C57 117.862 59.6863 120.548 63 120.548ZM65.6869 113.235C65.8821 113.04 65.8821 112.723 65.6869 112.528C65.4916 112.333 65.175 112.333 64.9798 112.528L62 115.508L61.0202 114.528C60.825 114.333 60.5084 114.333 60.3131 114.528C60.1179 114.723 60.1179 115.04 60.3131 115.235L61.5522 116.474C61.7995 116.722 62.2005 116.722 62.4478 116.474L65.6869 113.235Z"
        fill="#68C161"
      />
      <g filter="url(#filter4_b_476_33906)">
        <rect
          x="75"
          y="110.548"
          width="76"
          height="8"
          rx="4"
          fill="url(#paint6_linear_476_33906)"
          fill-opacity="0.3"
        />
        <rect
          x="75.4"
          y="110.948"
          width="75.2"
          height="7.2"
          rx="3.6"
          stroke="url(#paint7_linear_476_33906)"
          stroke-opacity="0.08"
          stroke-width="0.8"
        />
      </g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M63 140.548C66.3137 140.548 69 137.862 69 134.548C69 131.235 66.3137 128.548 63 128.548C59.6863 128.548 57 131.235 57 134.548C57 137.862 59.6863 140.548 63 140.548ZM65.6869 133.235C65.8821 133.04 65.8821 132.723 65.6869 132.528C65.4916 132.333 65.175 132.333 64.9798 132.528L62 135.508L61.0202 134.528C60.825 134.333 60.5084 134.333 60.3131 134.528C60.1179 134.723 60.1179 135.04 60.3131 135.235L61.5522 136.474C61.7995 136.722 62.2005 136.722 62.4478 136.474L65.6869 133.235Z"
        fill="#68C161"
      />
      <g filter="url(#filter5_b_476_33906)">
        <rect
          x="75"
          y="130.548"
          width="76"
          height="8"
          rx="4"
          fill="url(#paint8_linear_476_33906)"
          fill-opacity="0.3"
        />
        <rect
          x="75.4"
          y="130.948"
          width="75.2"
          height="7.2"
          rx="3.6"
          stroke="url(#paint9_linear_476_33906)"
          stroke-opacity="0.08"
          stroke-width="0.8"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_bd_476_33906"
        x="1"
        y="0.54834"
        width="208"
        height="208"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feGaussianBlur
          in="BackgroundImageFix"
          stdDeviation="12"
        />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_476_33906"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-8" />
        <feGaussianBlur stdDeviation="8" />
        <feComposite
          in2="hardAlpha"
          operator="out"
        />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_backgroundBlur_476_33906"
          result="effect2_dropShadow_476_33906"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_476_33906"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_b_476_33906"
        x="31"
        y="32.5483"
        width="160.255"
        height="144"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feGaussianBlur
          in="BackgroundImageFix"
          stdDeviation="12"
        />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_476_33906"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_476_33906"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_b_476_33906"
        x="51"
        y="46.5483"
        width="124"
        height="56"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feGaussianBlur
          in="BackgroundImageFix"
          stdDeviation="12"
        />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_476_33906"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_476_33906"
          result="shape"
        />
      </filter>
      <filter
        id="filter3_b_476_33906"
        x="51"
        y="66.5483"
        width="124"
        height="56"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feGaussianBlur
          in="BackgroundImageFix"
          stdDeviation="12"
        />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_476_33906"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_476_33906"
          result="shape"
        />
      </filter>
      <filter
        id="filter4_b_476_33906"
        x="51"
        y="86.5483"
        width="124"
        height="56"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feGaussianBlur
          in="BackgroundImageFix"
          stdDeviation="12"
        />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_476_33906"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_476_33906"
          result="shape"
        />
      </filter>
      <filter
        id="filter5_b_476_33906"
        x="51"
        y="106.548"
        width="124"
        height="56"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feGaussianBlur
          in="BackgroundImageFix"
          stdDeviation="12"
        />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_476_33906"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_476_33906"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_476_33906"
        x1="105"
        y1="24.5483"
        x2="105"
        y2="184.548"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--j-black)" />
        <stop
          offset="0.83"
          stop-color="var(--j-black)"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint1_linear_476_33906"
        x1="105"
        y1="24.5483"
        x2="105"
        y2="167.548"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--j-black)" />
        <stop
          offset="1"
          stop-color="var(--j-black)"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint2_linear_476_33906"
        x1="75"
        y1="74.5483"
        x2="151"
        y2="74.5483"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="rgb(var(--color-primary-400))" />
        <stop
          offset="1"
          stop-color="rgb(var(--color-primary-400))"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint3_linear_476_33906"
        x1="75"
        y1="74.5483"
        x2="150.934"
        y2="72.3103"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--j-black)" />
        <stop
          offset="0.13"
          stop-color="var(--j-black)"
          stop-opacity="0.1"
        />
        <stop
          offset="0.305"
          stop-color="var(--j-black)"
          stop-opacity="0.8"
        />
        <stop
          offset="0.565"
          stop-color="var(--j-black)"
          stop-opacity="0.1"
        />
        <stop
          offset="0.786809"
          stop-color="var(--j-black)"
          stop-opacity="0"
        />
        <stop
          offset="1"
          stop-color="#0000F0"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint4_linear_476_33906"
        x1="75"
        y1="94.5483"
        x2="151"
        y2="94.5483"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="rgb(var(--color-primary-400))" />
        <stop
          offset="1"
          stop-color="rgb(var(--color-primary-400))"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint5_linear_476_33906"
        x1="75"
        y1="94.5483"
        x2="150.934"
        y2="92.3103"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--j-black)" />
        <stop
          offset="0.13"
          stop-color="var(--j-black)"
          stop-opacity="0.1"
        />
        <stop
          offset="0.305"
          stop-color="var(--j-black)"
          stop-opacity="0.8"
        />
        <stop
          offset="0.565"
          stop-color="var(--j-black)"
          stop-opacity="0.1"
        />
        <stop
          offset="0.786809"
          stop-color="var(--j-black)"
          stop-opacity="0"
        />
        <stop
          offset="1"
          stop-color="#0000F0"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint6_linear_476_33906"
        x1="75"
        y1="114.548"
        x2="151"
        y2="114.548"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="rgb(var(--color-primary-400))" />
        <stop
          offset="1"
          stop-color="rgb(var(--color-primary-400))"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint7_linear_476_33906"
        x1="75"
        y1="114.548"
        x2="150.934"
        y2="112.31"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--j-black)" />
        <stop
          offset="0.13"
          stop-color="var(--j-black)"
          stop-opacity="0.1"
        />
        <stop
          offset="0.305"
          stop-color="var(--j-black)"
          stop-opacity="0.8"
        />
        <stop
          offset="0.565"
          stop-color="var(--j-black)"
          stop-opacity="0.1"
        />
        <stop
          offset="0.786809"
          stop-color="var(--j-black)"
          stop-opacity="0"
        />
        <stop
          offset="1"
          stop-color="#0000F0"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint8_linear_476_33906"
        x1="75"
        y1="134.548"
        x2="151"
        y2="134.548"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="rgb(var(--color-primary-400))" />
        <stop
          offset="1"
          stop-color="rgb(var(--color-primary-400))"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint9_linear_476_33906"
        x1="75"
        y1="134.548"
        x2="150.934"
        y2="132.31"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--j-black)" />
        <stop
          offset="0.13"
          stop-color="var(--j-black)"
          stop-opacity="0.1"
        />
        <stop
          offset="0.305"
          stop-color="var(--j-black)"
          stop-opacity="0.8"
        />
        <stop
          offset="0.565"
          stop-color="var(--j-black)"
          stop-opacity="0.1"
        />
        <stop
          offset="0.786809"
          stop-color="var(--j-black)"
          stop-opacity="0"
        />
        <stop
          offset="1"
          stop-color="#0000F0"
          stop-opacity="0"
        />
      </linearGradient>
    </defs>
  </svg>
</template>
